import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../src/views/home.vue";
import newsView from "../src/views/news.vue";
import newsDetailsView from "../src/views/newsDetails.vue";
import noticDetailsView from "../src/views/noticDetails.vue";
import treasureHuntView from "../src/views/treasureHunt.vue";
import discoverView from "../src/views/discover.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: { showTabbar: true },
    },
    {
        path: "/discover",
        name: "discover",
        component: discoverView,
        meta: { showTabbar: true },
    },
    {
        path: "/noticDetails",
        name: "noticDetails",
        component: noticDetailsView,
        meta: { showTabbar: false },
    },
    {
        path: "/news",
        name: "news",
        component: newsView,
        meta: { showTabbar: true },
    },
    {
        path: "/newsDetails/:id",
        name: "newsDetails",
        component: newsDetailsView,
        meta: { showTabbar: false },
    },
    {
        path: "/treasureHunt",
        name: "treasureHunt",
        component: treasureHuntView,
        meta: { showTabbar: true },
    },
];

const router = new createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;

