import Vant from 'vant';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import moment from 'moment';
// 2. 引入组件样式
import 'vant/lib/index.css';
import '@vant/touch-emulator';
import './rem.js';
moment.locale('zh-cn');
const app = createApp(App)
app.config.globalProperties.$moment = moment;
// Method 1. via app.use
app.use(router);
app.use(Vant);
app.mount('#app')
