import axios from 'axios'

// create an axios instance
const service = axios.create({
    baseURL: '', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30 * 1000 // request timeout,
})

// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        const params = { ...config.data }
        config.data = params
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
    */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        if (res.code !== 0 && res.code !== 20) {
            console.log(res.msg || 'Error')
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.code == 20) {
            return res
        }
        return res.data
    },
    error => {
        console.log(error.message)
        return Promise.reject(error)
    }
)

export default {
    post(url, data) {
        return service({ url, data, method: 'post' })
    },
    get(url, params) {
        return service({ url, params, method: 'get' })
    }
}
