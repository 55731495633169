<template>
  <div class="FlipClock">
    <Flipper ref="flipperHour1" />
    <Flipper ref="flipperHour2" />
    <span>:</span>
    <Flipper ref="flipperMinute1" />
    <Flipper ref="flipperMinute2" />
    <span>:</span>
    <Flipper ref="flipperSecond1" />
    <Flipper ref="flipperSecond2" />
  </div>
</template>

<script>
import Flipper from "./Flipper";

export default {
  name: "FlipClock",
  props: {
    seconds: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      timer: null,
      cd: 0,
      flipObjs: [],
    };
  },
  watch: {
    seconds(value) {
      this.cd = value;
      this.init();
      if (this.seconds > 0) {
        this.run();
      }
    },
  },
  components: {
    Flipper,
  },
  mounted() {
    this.flipObjs = [this.$refs.flipperHour1, this.$refs.flipperHour2, this.$refs.flipperMinute1, this.$refs.flipperMinute2, this.$refs.flipperSecond1, this.$refs.flipperSecond2];
  },
  methods: {
    // 初始化数字
    init() {
      clearInterval(this.timer);
      let cdStr = this.format(this.seconds);
      for (let i = 0; i < this.flipObjs.length; i++) {
        this.flipObjs[i].setFront(cdStr[i]);
      }
    },
    // 开始计时
    run() {
      this.timer = setInterval(() => {
        if (this.cd == 0) {
          clearInterval(this.timer);
          this.$emit("finish");
          return;
        }
        let prev = this.format(this.cd);
        this.cd--;
        let curr = this.format(this.cd);
        for (let i = 0; i < this.flipObjs.length; i++) {
          if (prev[i] === curr[i]) {
            continue;
          }
          this.flipObjs[i].flipDown(prev[i], curr[i]);
        }
      }, 1500);
    },
    format(seconds) {
      let hour = Math.floor(seconds / 3600);
      let min = Math.floor((seconds % 3600) / 60);
      let sec = seconds % 60;
      // 将小时、分钟、秒数格式化为两位数
      return [hour >= 10 ? hour : "0" + hour, min >= 10 ? min : "0" + min, sec >= 10 ? sec : "0" + sec].join("");
    },
  },
};
</script>

<style scoped>
.FlipClock {
  display: flex;
  align-items: center;
}

.FlipClock span {
  margin: 0 5px;
}
</style>
