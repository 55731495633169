<template>
  <van-nav-bar title="新闻详情" left-arrow @click="back()" />
  <van-skeleton title avatar :row="6" :loading="loading">
    <div class="details-page">
      <div class="details-header">
        <div class="title">{{ details.Title }}</div>
        <div class="details-info">
          <div class="item">
            <div class="label">{{ formatDate(details.UpdateTime) }}</div>
          </div>
          <div class="item">
            <div class="label">阅读: {{ details.Click }}</div>
          </div>
        </div>
      </div>
      <div class="details-body">
        <div class="zhaiyao">
          <p>{{ details.Zhaiyao }}</p>
        </div>
        <div class="content-text" v-html="details.ContentHTML"></div>
      </div>
    </div>
  </van-skeleton>
</template>

<script>
import { getArticleDetails } from "@/api";
export default {
  name: "newsDetails",
  components: {},
  data() {
    return {
      details: {},
      loading: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getArticleDetails();
  },
  methods: {
    async getArticleDetails() {
      this.loading = true;
      const res = await getArticleDetails(this.$route.params.id);
      this.details = res;
      this.loading = false;
    },
    formatDate(str) {
      const date = new Date(str);
      return date.toISOString().slice(0, 16).replace("T", " ");
    },
    back() {
      history.back();
    },
  },
};
</script>

<style lang="scss">
.details-page {
  margin: 10px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;

  .details-header {
    .title {
      font-size: 18px;
      font-weight: 600;
      color: #252626;
      text-align: center;
      margin-bottom: 10px;
    }

    .details-info {
      display: flex;
      justify-content: space-between;
      font-family: Cairo;
      font-size: 10px;
      font-weight: 500;
      color: #999999;
    }
  }

  .details-body {
    .zhaiyao {
      color: #777;
      border-bottom: 2px solid #e1e1e1;
      border-top: 2px solid #e1e1e1;
      padding: 10px;
      background: #f0f0f0;
      margin: 10px 0;
      p {
        font-size: 12px;
      }
    }
  }
}
</style>
