<template>
  <van-nav-bar title="新闻" />
  <div class="news-page">
    <div class="news-content">
      <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="initArticleList(true)">
        <van-cell class="item" v-for="(item, index) in articleList" :key="item.Id" @click="openNewsPopup(index)">
          <div class="item-img">
            <van-image :src="item.ImgUrl" width="100px" height="80px" fit="cover"></van-image>
          </div>
          <div class="item-info">
            <div class="info-title">{{ item.Title }}</div>
            <div class="item-date">
              <div class="date">{{ formatDate(item.UpdateTime) }}</div>
              <div class="info-click">阅读: {{ item.Click }}</div>
            </div>
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
  <van-popup v-model:show="showNewsPopup" closeable close-icon="close" position="bottom" :style="{ height: '100%' }">
    <van-nav-bar title="新闻详情" />
    <div class="details-page">
      <div class="details-header">
        <div class="title">{{ newsDetailsPopup.Title }}</div>
        <div class="details-info">
          <div class="item">
            <div class="label">{{ formatDate(newsDetailsPopup.UpdateTime) }}</div>
          </div>
          <div class="item">
            <div class="label">阅读: {{ newsDetailsPopup.Click }}</div>
          </div>
        </div>
      </div>
      <div class="details-body">
        <div class="zhaiyao">
          <p>{{ newsDetailsPopup.Zhaiyao }}</p>
        </div>
        <div class="content-text" v-html="newsDetailsPopup.ContentHTML"></div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getArticleList } from "@/api";
export default {
  name: "newsView",
  components: {},
  data() {
    return {
      loading: false,
      params: {
        PageNumber: 0,
        PageSize: 20,
        CategoryId: 0,
      },
      articleList: [],
      finished: false,
      showNewsPopup: false,
      newsDetailsPopup: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    async initArticleList(loadMore) {
      if (loadMore) {
        this.params.PageNumber += 1;
      } else {
        this.params.PageNumber = 1;
        this.articleList = [];
        this.finished = false;
      }
      this.loading = true;
      const res = await getArticleList(this.params);
      this.finished = res[0].length < this.params.PageSize;
      this.articleList.push(...res[0]);
      this.loading = false;
    },
    openNewsPopup(index) {
      this.newsDetailsPopup = this.articleList[index];
      this.showNewsPopup = true;
    },
    goNewsDetails(id) {
      this.$router.push({
        name: "newsDetails",
        params: {
          id: id,
        },
      });
    },
    formatDate(str) {
      const date = new Date(str);
      return date.toISOString().slice(0, 16).replace("T", " ");
    },
  },
};
</script>

<style lang="scss">
.news-page {
  margin: 10px 10px 50px 10px;
}

.news-content {
  .item {
    padding: 8px;
    .van-cell__value {
      display: flex;
      img {
        border-radius: 10px;
      }
      .item-info {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 10px;
        .info-title {
          flex: 1;
          font-size: 14px;
          font-weight: 500;
          color: #252626;
          width: 100%;
          max-height: 80px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          word-wrap: break-word;
          text-align: left;
        }
        .item-date {
          display: flex;
          width: 100%;
          height: auto;
          justify-content: space-between;
          align-items: end;
          font-family: Cairo;
          font-size: 10px;
          font-weight: 500;
          color: #999999;
        }
      }
    }
  }
}

.details-page {
  margin: 10px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;

  .details-header {
    .title {
      font-size: 18px;
      font-weight: 600;
      color: #252626;
      text-align: center;
      margin-bottom: 10px;
    }

    .details-info {
      display: flex;
      justify-content: space-between;
      font-family: Cairo;
      font-size: 10px;
      font-weight: 500;
      color: #999999;
    }
  }

  .details-body {
    .zhaiyao {
      color: #777;
      border-bottom: 2px solid #e1e1e1;
      border-top: 2px solid #e1e1e1;
      padding: 10px;
      background: #f0f0f0;
      margin: 10px 0;
      p {
        font-size: 12px;
      }
    }
  }
}
</style>
